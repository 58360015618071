@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@500&family=Space+Grotesk&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo&display=swap");
html {
  padding: 0px !important;
  margin: 0px !important;
}
body {
  text-align: center !important;
  margin: 0px !important;
  font-family: "Cairo", sans-serif !important;
}
.ant-checkbox-wrapper{
  font-family: "Cairo", sans-serif !important;
}
.font {
  color: #100e23;
  /* font-family: "Space Grotesk" !important; */
  font-style: normal;
  font-weight: 500;
}
.yellow_back {
  background-color: #eece52;
}
.letters_limit {
  word-wrap: break-word;
  width: 100%;
}
.review_progress .css-eglki6-MuiLinearProgress-root {
  background-color: #f2f2f2 !important;
  height: 10px;
  border-radius: 2px;
}
.review_progress .css-5xe99f-MuiLinearProgress-bar1 {
  background-color: #f2c94c !important;
}
.namestyle {
  /* font-family: "Space Grotesk"; */
  width: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  /* margin-left: 75px; */
}
.camera {
  position: absolute;
  width: 200px;
  height: 200px;
  /* background-color: rgba(15, 14, 37, 0.67); */
  border-radius: 50%;
  padding-top: 70px;
  z-index: 2;
  top: 0;
  /* left: 25%; */
  display: none;
  text-align: center !important;
}
.camera_div {
  position: relative;
  z-index: 1;
  padding: 0px !important;
  display: inline-block;
  /* transform: scaleX(-1.1); */
}
.camera_div:hover .camera {
  display: block;
}

.camera_div:hover {
  opacity: 0.5;
}

.radius_4 {
  border-radius: 4px !important;
}
.radiusLD_4 {
  border-radius: 0px 0px 4px 4px;
}
.radiusU_4 {
  border-radius: 4px 4px 0px 0px;
}

.radiusLU_4 {
  border-radius: 0px 4px 0px 0px;
}
.radiusRU_4 {
  border-radius: 4px 0px 0px 0px;
}

.label_left {
  /* font-family: "Space Grotesk" !important; */
  font-size: 14px;
  float: left;
}
.vh {
  min-height: 100vh;
}
a {
  text-decoration: none;
}
.w-60 {
  width: 60%;
}
.w-20 {
  width: 20%;
}
.w-40 {
  width: 40%;
}
.w-10 {
  width: 10% !important;
}
.w-90 {
  width: 90%;
}
.w-30 {
  width: 30%;
}
.w-70 {
  width: 70%;
}
.w-80 {
  width: 80% !important;
}
.font_11 {
  font-size: 11px !important;
}
.font_13 {
  font-size: 13px !important;
}
.font_12 {
  font-size: 12px !important;
}
.font_15 {
  font-size: 15px !important;
}
.font_19 {
  font-size: 19px !important;
}
.font_20 {
  font-size: 20px !important;
}
.svg_fill {
  fill: #100e23 !important;
}
.teams_table {
  height: 250px;
  overflow-y: auto;
}
.progress_title {
  color: rgba(255, 255, 255, 0.392);
}
.progress_card {
  background-image: linear-gradient(to left, #14102adc, #14102adc),
    url(./imgs/teamBack.jpeg) !important;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  color: white;
  border-radius: 25px;
  /* opacity: 0.4; */
  position: relative;
}
.progress_card2 {
  background-image: linear-gradient(to left, #14102ad8, #14102ad8),
    url(./imgs/image16.png) !important;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  color: white;
  border-radius: 25px;
  /* opacity: 0.4; */
  position: relative;
}
.progress_card2 .css-5im911-MuiSpeedDial-root {
  z-index: 0 !important;
}
.hover_icon {
  transition: hover 0.5s ease;
}
.hover_icon:hover {
  /* padding-bottom: 21px !important; */
  /* transform: translate(1.5,1.5) !important; */
  /* width: 50px; */
  /* margin-bottom: 40px !important; */
  /* transition: hover 0.5s ease; */
  animation: shakeImg 1s;
  animation-iteration-count: initial;
}
@keyframes shakeImg {
  0% {
    transform: translate(1px, 1px) rotate(5deg);
  }
  /* 20% { transform: translate(-3px, 0px) rotate(10deg); } */
  /* 30% { transform: translate(3px, 2px) rotate(-10deg); } */
  50% {
    transform: translate(7px, 2px) rotate(20deg);
  }
  /* 70% { transform: translate(3px, 1px) rotate(-25deg); } */
  /* 90% { transform: translate(1px, 2px) rotate(10deg); } */
  /* 100% { transform: translate(1px, -2px) rotate(-10deg); } */
}
.progress_card1 {
  transition: transform 1.5s ease !important;
}
.progress_card1:hover {
  background-image: linear-gradient(
      to left,
      rgba(61, 55, 18, 0.856),
      rgba(61, 55, 18, 0.856)
    ),
    url(./imgs/teamBack.jpeg) !important;
  opacity: 0.5;
}
.progress_card1:hover button {
  display: block !important;
  width: 30%;
}
.teams_table::-webkit-scrollbar {
  /* display: none; */
  width: 10px;
  background-color: #f5f5f5;
  border-radius: 10px;
}
.teams_table::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #1c183d;
}
.custom_menu {
  height: 150px;
  overflow-y: auto;
}
.container_card {
  overflow: hidden !important;
  height: 300px !important;
  padding: 0px !important;
  border-radius: 20px;
}
.container_card_gamer {
  overflow: hidden !important;
  height: 400px !important;
  padding: 0px !important;
  border-radius: 20px;
}
.team_cover {
  background-image: url(./imgs/teamBack.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 250px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  background-position-x: 50% !important;
  background-position-y: 15% !important;
}
.team_cover img {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  margin-top: 10px;
}
.team_card {
  height: 300px;
  border-radius: 20px;
  position: absolute;
  width: 100%;
  z-index: 1;
  transition: transform 0.5s ease;
}

.gamer_card {
  height: 400px;
  border-radius: 20px;
  position: absolute;
  width: 100%;
  z-index: 1;
  transition: transform 0.5s ease;
}

.black-layer1 {
  background: linear-gradient(to left, #14102ab3, #100d22a6) !important;
  width: 100%;
  border-radius: 20px;
  height: 300px;
  margin-top: 15px !important;
}

.black-layer {
  background: linear-gradient(to left, #14102ab3, #100d22a6) !important;
  width: 100%;
  height: 300px;
  border-radius: 20px;
  padding-top: 15px;
  z-index: 3;
  position: absolute;
}
.black-layer-gamer {
  background: linear-gradient(to left, #14102ab3, #100d22a6) !important;
  width: 100%;
  height: 400px;
  border-radius: 20px;
  padding-top: 15px;
  z-index: 3;
  position: absolute;
}

.hexagon {
  width: 110px;
  height: 110px;
  /* -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%); */
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  /* margin-left: 23%; */
  margin: auto !important;
}

.container_card:hover .team_card {
  transform: scale(1.2) !important;
}
.team-img {
  width: 100%;
  /* height: auto; */
  display: block;
  object-fit: cover;
  /* margin-top: 15px; */
}
.remove_index {
  z-index: unset !important;
}
/* style of loading page */
.load-page {
  width: 100%;
  position: fixed;
  height: 100% !important;
  background-color: #080619dc;
  z-index: 999999;
  top: 0px;
  padding-top: 17%;
  left: 0px !important;
  right: 0px !important;
}

.empty_kids {
  padding-top: 17% !important;
}
.empty_teams {
  padding-top: 10% !important;
}
.memberChallenge_scroll {
  height: 430px;
  overflow-y: auto;
  margin-top: 1w-cardpx !important;
}
.memberChallenge_scroll::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}
.memberChallenge_scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #eece52;
}

.manager_scroll {
  height: 600px;
  overflow-y: auto;
  margin-top: 1w-cardpx !important;
}
.manager_scroll::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}
.manager_scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #eece52;
}

.teamPage_row {
  height: 400px !important;
  /* display: flex; */
  /* flex-wrap: nowrap; */
  overflow-y: auto;
  margin-top: 1w-cardpx !important;
}
.teamPage_row::-webkit-scrollbar {
  /* display: none; */
  width: 10px;
  background-color: #f5f5f5;
  border-radius: 10px;
}
.teamPage_row::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #eece52 !important;
}

.childs_row {
  height: 430px;
  /* display: flex; */
  /* flex-wrap: nowrap; */
  overflow-y: auto;
  margin-top: 1w-cardpx !important;
}
.childs_row::-webkit-scrollbar {
  /* display: none; */
  width: 10px;
  background-color: #f5f5f5;
  border-radius: 10px;
}
.childs_row::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #1c183d;
}
.childs_row .child_one img {
  /* flex: 0 0 auto; */
  cursor: pointer;
}
.child_one {
  position: relative;
  z-index: 1;
}
.kid-mission .list-group {
  height: 250px;
  overflow-y: auto;
  /* -ms-overflow-style: none;
  scrollbar-width: none;   */
}
.Rep-mission .list-group {
  height: 250px;
  overflow-y: auto;
  /* -ms-overflow-style: none;
  scrollbar-width: none;   */
}
.kid-mission .list-group::-webkit-scrollbar {
  /* display: none; */
  width: 10px;
  background-color: #f5f5f5;
  border-radius: 10px;
}
.Rep-mission .list-group::-webkit-scrollbar {
  /* display: none; */
  width: 10px;

  border-radius: 10px;
}
.kid-mission .list-group::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #eece52;
}

.check_child {
  position: absolute;
  width: 40px !important;
  height: 40px !important;
  top: 45px;
  text-align: center;
  z-index: 2;
  left: 40%;
}
.check_child1 {
  position: absolute;
  width: 40px !important;
  height: 40px !important;
  top: 60px;
  text-align: center;
  z-index: 2;
  left: 40%;
}
.check_child2 {
  position: absolute;
  width: 40px !important;
  height: 40px !important;
  top: 70px;
  text-align: center;
  z-index: 2;
  left: 40%;
}

.check_child3 {
  position: absolute;
  width: 50px !important;
  height: 50px !important;
  top: 100px;
  text-align: center;
  z-index: 2;
  left: 40%;
}
.add_icon {
}

.kid_mis {
  position: relative;
  cursor: pointer;
}
.check_miss {
  position: absolute;
  width: 50px !important;
  height: 50px !important;
  top: 13px;
  text-align: center;
  z-index: 2;
  right: 15%;
}
.icon_miss {
  position: absolute;
  width: 30px !important;
  height: 30px !important;
  top: 13px;
  text-align: center;
  z-index: 2;
}
.icon_miss1 {
  position: absolute;
  width: 30px !important;
  height: 30px !important;
  top: 13px;
  text-align: center;
  z-index: 2;
}
.check_miss1 {
  position: absolute;
  width: 50px !important;
  height: 50px !important;
  top: 13px;
  text-align: center;
  z-index: 2;
  right: 15%;
}
.rotate90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.load {
  width: 10% !important;
  color: #f9af22 !important;
  margin-left: 45%;
}

#message,
#message1,
#message_parent {
  display: none;
}
.bold {
  font-weight: bolder;
}
#sign {
  display: block;
}
#connect,
#connect_parent {
  display: none;
}
.load {
  margin: auto;
  text-align: center;
}
.link {
  margin-top: 30px !important;
  text-decoration: underline;
}
.link a {
  cursor: pointer !important;
}
.point {
  cursor: pointer !important;
}
.head {
  background: #2a49b6;
  text-align: center;
  color: white;
  /* font-family: "Manrope"; */
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
}
.yellow {
  color: #eece52;
}
.yellow-outline-btn {
  color: #eece52;
  border: 2px solid #eece52;
  border-radius: 2px;
  /* font-family: "Space Grotesk"; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}
.yellow-outline-btn:hover {
  color: #100e23;
  background-color: #eece52;
}
.yellow-outline-btn:hover svg path {
  fill: #100e23;
}
.white-outline-btn {
  color: white;
  border: 2px solid white;
  border-radius: 2px;
  /* font-family: "Space Grotesk"; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}
.white-outline-btn:hover {
  color: #2a49b6;
  background-color: white;
}

.white-outline-btn1 {
  color: white;
  border: 2px solid #1a182d;
  /* font-family: "Space Grotesk"; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  background: #1a182d;
}
.white-outline-btn1:hover {
  color: #1a182d;
  background-color: white;
  border: 2px solid #1a182d;
}
.white-outline-btn:hover svg path {
  fill: #2a49b6;
}

.grey {
  color: #656382;
}
.white-btn {
  background: #ffffff;
  border-radius: 48px;
  color: #2a49b6;
  /* font-family: "Space Grotesk"; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}
.black-btn {
  background: #100e23;
  border-radius: 48px;
  color: #eece52;
  /* font-family: "Space Grotesk"; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}
.yellow-btn {
  background: #eece52 !important;
  border-radius: 2px;
  padding: 9px 16px 11px;
  font-weight: 500;
  font-size: 14px;
  color: #100e23;
}
.gray-btn {
  background: #e6e4dd !important;
  border-radius: 2px;
  padding: 9px 16px 11px;
  font-weight: 500;
  font-size: 14px;
  color: #100e23;
}

.bg_blue {
  background-color: #2a49b6;
}
.bg_red {
  background-color: red;
  border-color: red;
  color: white;
}
.bg_black {
  background-color: #100e23 !important;
}
.bg-grey {
  background: #1a182d;
  border-radius: 4px;
}
.hr-yellow {
  background-color: #eee056;
  height: 2px !important;
}
footer p {
  font-size: 13px;
  /* font-family: "Manrope", sans-serif; */
  font-weight: 500, medium;
}
footer h6 {
  color: #eee056;
  /* font-family: "Space Grotesk", sans-serif; */
}
footer i {
  font-size: 24px;
}
footer input {
  font-size: 13px !important;
  border-right: none !important;
  padding: 12px !important;
  caret-color: #100e23 !important;
  box-shadow: none !important;
}
footer .input-group-text {
  background-color: white !important;
}
.footer_input {
  background-color: #1a182d !important;
  color: white !important;
}
.sign {
  /*background-image: url(./imgs/signback.png),  linear-gradient(to right,#100E23  , #100e23fa  );*/
  background-image: url(https://web1.veryality.com/assets/imgs/signback.png),
    linear-gradient(to right, #100e23, #100e23fa);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.sign h2 {
  color: #eece52;
  font-weight: 700, Bold;
}
.flash1 {
  position: absolute;
  width: 306px;
  height: 306px;
  left: 80%;
  top: 300px;
  background: #f84e86;
  opacity: 0.3;
  filter: blur(208px);
}
.flash2 {
  position: absolute;
  width: 296px;
  height: 295px;
  left: 80%;
  top: 300px;
  background: #eece52;
  opacity: 0.5;
  filter: blur(208px);
}
.flash3 {
  position: absolute;
  width: 288px;
  height: 287px;
  left: 80%;
  top: 300px;
  background: #eee056;
  opacity: 0.2;
  filter: blur(208px);
}
.form-dark select,
.form-dark select:focus {
  background-color: #100e23;
  color: white !important;
  border: none;
  font-size: 12px;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: none;
  caret-color: #eee056 !important;
}
.form-white {
  width: 435px;
}
#browser::-webkit-calendar-picker-indicator {
  opacity: 100;
  color: #26253600;
  /* this error */

  /* background-image: url(
    data:image/svg + xml,
    %3csvgxmlns="http://www.w3.org/2000/svg"viewBox="0 0 16 16"%3e%3cpathfill="none"stroke="white"stroke-linecap="round"stroke-linejoin="round"stroke-width="2"d="M2 5l6 6 6-6"/%3e%3c/svg%3e
  ); */
  width: 5px !important;
}
.form-white input,
.form-white select,
.form-white input:focus,
.form-white select:focus,
.form-white input::placeholder {
  background-color: #262536 !important;
  color: white !important;
  border: none;
  font-size: 12px;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: none;
  caret-color: #eee056 !important;
}

.multi-select {
  background-color: #262536 !important;
  color: white !important;
  border: none;
  font-size: 12px;
  padding-top: 0px;
  padding-bottom: 0px;
  box-shadow: none;
  caret-color: #eee056 !important;
}
.form-white .form-check-input,
.form-white .form-check-input:focus {
  padding: 10px;
  background-color: #353155 !important;
  cursor: pointer;
}
.form-white .form-control,
.form-white .form-select {
  position: relative;
}

.was-validated select:valid {
  background-image: none !important;
}

.was-validated select:invalid {
  background-image: none !important;
}
input {
  caret-color: white !important;
}
.form-dark .form-select {
  /* this error */

  /* background-image: url(
    data:image/svg + xml,
    %3csvgxmlns="http://www.w3.org/2000/svg"viewBox="0 0 16 16"%3e%3cpathfill="white"stroke="white"stroke-linecap="round"stroke-linejoin="round"stroke-width="2"d="M2 5l6 6 6-6"/%3e%3c/svg%3e
  ); */
}
.form-white .form-select {
  /* this error */

  /* background-image: url(
    data:image/svg + xml,
    %3csvgxmlns="http://www.w3.org/2000/svg"viewBox="0 0 16 16"%3e%3cpathfill="none"stroke="white"stroke-linecap="round"stroke-linejoin="round"stroke-width="2"d="M2 5l6 6 6-6"/%3e%3c/svg%3e
  ); */
}

.select-watch {
  background-color: #262536;
  border: none;
  font-size: 12px;
}
.sign .nav-pills .nav-link {
  border-radius: 0px !important;
  padding-left: 43px;
  padding-right: 43px;
  border-bottom: 2px solid #ffffff83;
  color: #ffffff83;
}
.pdash .nav-pills .nav-link {
  border-radius: 0px !important;
  padding-left: 43px;
  padding-right: 43px;
  color: #ffffff;
}
.sign .nav-pills .nav-link svg path {
  fill: #ffffff83;
}
.sign .nav-pills .nav-item .active,
.pdash .nav-pills .nav-item .active {
  background-color: #100e2300 !important;
  color: #eece52;
  border-bottom: 2px solid #eece52;
}
.sign .nav-pills .active svg path {
  fill: #eece52;
}

.level-nav {
  color: rgba(255, 255, 255, 0.392);
  border-left: 2px solid rgba(255, 255, 255, 0.392);
  margin-left: 15px !important;
  padding-left: 11px !important;
}

.font-13 {
  font-size: 13px;
}

.profile {
  padding-left: 30px !important;
  padding-right: 5px;
  color: #eee056;
  cursor: pointer;
}

.drop-account {
  background-color: #100e2300;
  border: none;
}
.user-nav .dropdown-toggle::after {
  border-top: 0.3em solid #eee056 !important;
}
.user-nav .dropdown-menu li {
  cursor: pointer;
}
.dash {
  display: flex;
  justify-content: center;
}

.parent-watch {
  background-color: #eece52;
}
.kid-manage .dropdown li {
  cursor: pointer;
}
.parent-watch .dropdown-toggle::after,
.kid-manage .dropdown-toggle::after,
.user-nav .notifs::after {
  display: none;
}
.parent-watch .btn-check:focus + .btn,
.parent-watch .btn:focus,
.kid-manage .btn-check:focus + .btn,
.kid-manage .btn:focus {
  box-shadow: none !important;
}

.dist .dropdown-toggle::after {
  display: none;
}
.dist .btn-check:focus + .btn,
.dist .btn:focus {
  box-shadow: none !important;
}

.parent-watch .title {
  /* font-family: "Space Grotesk"; */
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  text-align: left;
  letter-spacing: -0.005em;
  color: #100e23;
  line-height: 160%;
}
.activity {
  font-size: 12px;
  color: #26253688;
  /* font-family: "Space Grotesk"; */
  font-style: normal;
  font-weight: 700;
  text-align: left;
}
.calory {
  /* font-family: "Space Grotesk"; */
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  color: #100e23;
  text-align: left;
  line-height: 25px !important;
}
.heart {
  /* font-family: "Space Grotesk"; */
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  text-align: left;
  line-height: 25px !important;
}
.heart1 {
  /* font-family: "Space Grotesk"; */
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  text-align: left;
  line-height: 25px !important;
}
.connect {
  background: #ffffff;
  text-align: center;
  /* font-family: "Manrope"; */
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  color: #656382;
}
.blue-btn {
  background: #2a49b6;
  border-radius: 2px;
  color: white;
  padding: 9px 16px 11px;
  font-weight: 500;
  font-size: 14px;
}
.blue-btn:hover {
  background: #2a49b6;
  color: white;
}
#confirmو #pass {
  display: none;
}
.watch-img img {
  border-radius: 20px;
  padding-top: 5px !important;
  width: 330px !important;
}
.watch-img1 img {
  border-radius: 20px;
  padding-top: 5px !important;
  width: 200px !important;
}

.fitbit {
  margin-top: -330px;
  position: relative;
  padding-right: 50px;
}
.dist {
  margin-left: 36px !important;
}
.level {
  /* font-family: "Space Grotesk"; */
  font-style: normal;
  font-weight: 700;
  font-size: 144px;
  color: #eece52;
  line-height: 100px !important;
  text-align: left;
}
.all {
  /* font-family: "Space Grotesk"; */
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: white;
  opacity: 0.4;
}
.divi {
  /* font-family: "Space Grotesk"; */
  font-style: normal;
  font-weight: 200;
  font-size: 24px;
  color: white;
  opacity: 0.4;
}
.level-img {
  background-image: url(../public/assets/imgs/levelback.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.level-char img {
  width: 400px;
  height: 410px;
  margin-top: -70px;
}
.level-char1 img {
  width: 90%;
  height: 300px;
  margin-top: 0px;
}
.poly {
  top: -335px !important;
  position: relative;
  margin-bottom: -350px;
}
.poly1 {
  top: -325px !important;
  position: relative;
  margin-bottom: -350px;
}
.all-char {
  position: relative;
  text-align: center;
}
.all-char p {
  padding: 10px;
  background-color: #eece52;
  color: #100e23;
  /* font-family: "Space Grotesk"; */
  font-style: normal;
  font-weight: 500;
  font-size: 8.96284px;
  position: absolute;
  border-radius: 2.28041px;
  bottom: 80px;
  left: 30px;
}
.all-char h6 {
  position: absolute;
  z-index: 999;
  margin: auto;
  left: 25%;
  top: 30%;
  color: white;
  /* font-family: "Space Grotesk"; */
  font-style: normal;
  font-weight: 700;
  font-size: 60.2791px;
  word-wrap: break-word;
  width: 230px;
}
.character_name {
  font-size: 40.2791px;
  left: 15% !important;
  top: 40% !important;
}
.limit_word {
  /* max-width: 10ch !important; */
}
.break {
  text-wrap: break-word;
}
.game {
  border-radius: 4px;
  /* width: 320px; */
  /* margin-right: 20px; */
  padding-left: 0px;
  padding-right: 0px;
}
.game .card {
  background: none;
}

.game .card-header {
  text-align: left;
  color: white;
  padding-left: 0px;
  padding-right: 0px;
}
.game .card-footer {
  /* font-family: "Manrope"; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding-left: 0px;
  padding-right: 0px;

  padding-bottom: 24px;

}
.game .card-footer .btn  {
  margin-right: 16px !important;
  margin-left: 16px !important;;
}
.game .card-body{
  padding-right: 0px;
  padding-left: 0px;
}
.game .card-body img {
  width: 230px;
  height: 300px;
}
.game .card-body .notactive {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  opacity: 0.2;
}

.pre-brows {
  z-index: 1;
  position: relative;
}
.brows {
  /*position: relative;*/
  z-index: 999;
  margin-top: -220px !important;
  padding-top: 140px;
  padding-bottom: 45px;
  background: linear-gradient(180deg, rgba(26, 24, 45, 0.144) 0%, #1a182d 60%);
}

.kid-manage {
  /* background: linear-gradient(248.61deg, rgba(16, 14, 35, 0.5) 2.74%, rgba(16, 14, 35, 0) 67.14%),
              linear-gradient(0deg, #100E23 0%, rgba(16, 14, 35, 0) 21.06%),
              linear-gradient(90deg, #100E23 0%, rgba(16, 14, 35, 0) 56.84%),
                rgba(16, 14, 35, 0.95); */
  background-image: url("../public/assets/imgs/backkid.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  box-shadow: 0px 1px 0px #f8d85c;
}
.kid {
  /* font-family: "Space Grotesk"; */
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
}
.kid-char {
  background-color: #eece52;
  border-radius: 8px;
}
.kid-mission {
  background: #100e23;
  border: 1px solid #eece52;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
}
.Rep-mission {
  /* background: #100E23; */
  border: 1px solid #eece52;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
}
.kid-mission p {
  /* font-family: "Space Grotesk"; */
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: white;
}

.kid-mission .list-group-item {
  background: #1a182d;
  text-align: left;
}
.kid-mission .btn {
  bottom: 20px !important;
  /* position: absolute; */
  width: 95%;
  /* left: 2.5%; */
}
.tit {
  /* font-family: "Space Grotesk"; */
  font-style: normal;
  font-weight: 700;
  font-size: 22px !important;
  color: white;
  margin-bottom: 2px;
}
.desc {
  /* font-family: "Manrope"; */
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  color: white;
  opacity: 0.5;
}

.kid-char .kid-av {
  width: 130px !important;
  height: 130px;
  margin-top: 80px;
}
.kid-char .img1 {
  width: 450px !important;
  height: 400px !important;
}

.kid-char p {
  /* font-family: "Space Grotesk"; */
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  color: #100e23;
  margin-bottom: 0px;
}

.kid-char a {
  /* font-family: "Manrope"; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #100e23;
}
.vector {
  -ms-transform: translateY(40%);
  transform: translateY(40%);
}
.kid-reward {
  background: linear-gradient(180deg, rgba(42, 73, 182, 0) 0%, #2a49b6 79%);
  position: relative;
  z-index: 1;
}
.retit {
  /* font-family: "Space Grotesk"; */
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  margin: 0px;
}
.added {
  /* font-family: "Space Grotesk"; */
  font-style: normal;
  font-weight: 700;
  font-size: 144px;
  line-height: 144px;
}
.comp {
  /* font-family: "Space Grotesk"; */
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 112px;
}
.reward {
  height: 400px;
}
.reward .card {
  text-align: left;
  background-color: #100e23;
  color: white;
  border-radius: 8px;
  height: 400px;
  width: 200px;
}
.reward .card .card-body {
  /* font-family: "Space Grotesk"; */
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  margin: 0px;
}

.claim-num {
  color: #2a49b6;
  /* font-family: "Space Grotesk"; */
  font-style: normal;
  font-weight: 700;
  font-size: 76px;
  margin: 0px;
  line-height: 100px;
}
.reward_hero {
  height: 700px;
  overflow-y: auto;
}
.reward_hero::-webkit-scrollbar {
  /* display: none; */
  width: 10px;
  background-color: #f5f5f5;
  border-radius: 10px;
}
.reward_hero::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #eece52;
}
.reward_hero_m {
  height: 500px;
  overflow-x: auto;
  flex-wrap: nowrap;
}
.reward_hero_m::-webkit-scrollbar {
  /* display: none; */
  width: 10px;
  background-color: #f5f5f5;
  border-radius: 10px;
}
.reward_hero_m::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #eece52;
}

.all-rewards {
  position: absolute;
  z-index: 999;
  bottom: 0px;
  padding-top: 10px;
  padding-bottom: 20px;
  /*background: linear-gradient(180deg, rgba(42, 73, 182, 0) 0%, #2A49B6 49%);*/
  width: 100%;
  right: 0px;
}
.all-rewards-m {
  /* position: absolute; */
  z-index: 999;
  bottom: 0px;
  padding-top: 15px;
  padding-bottom: 20px;
  /*background: linear-gradient(180deg, rgba(42, 73, 182, 0) 0%, #2A49B6 49%);*/
  width: 100%;
  right: 0px;
}
.mission {
  background-color: #eece52;
  position: relative;
  z-index: 1;
  color: #100e23;
}

.all-missions {
  position: absolute;
  z-index: 999;
  bottom: 0px;
  padding-top: 150px;
  padding-bottom: 70px;
  background: linear-gradient(
    180deg,
    rgba(238, 207, 82, 0.441) 0%,
    #eece52 49%
  );
  width: 75%;
  right: 0px;
}
.mission table th {
  /* font-family: "Manrope"; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #262536;
}
.mission table thead {
  border-color: #26253633 !important;
}
.ta-mis {
  /* font-family: "Space Grotesk"; */
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
}
.ta-dis {
  /* font-family: "Space Grotesk"; */
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  opacity: 0.5;
}

.mobile {
  display: none !important;
}

.profile .card {
  width: 500px;
  background-color: #efefef;
  border: none;
  transition: all 0.5s;
  border-radius: "10px";
  cursor: default;
}
.no-pointer {
  cursor: default !important;
}
.image img {
  transition: all 0.5s;
}

.profile .card:hover .image img {
  transform: scale(1.5);
}

.btn11 {
  height: 140px;
  width: 140px;
  border-radius: 50%;
}

.name {
  font-size: 22px;
  font-weight: bold;
}

.idd {
  font-size: 14px;
  font-weight: 600;
}

.idd1 {
  font-size: 12px;
}

.number {
  font-size: 22px;
  font-weight: bold;
}

.follow {
  font-size: 12px;
  font-weight: 500;
  color: #444444;
}

.btn1 {
  height: 40px;
  width: 150px;
  border: none;
  background-color: #000;
  color: #aeaeae;
  font-size: 15px;
}

.text span {
  font-size: 13px;
  color: #545454;
  font-weight: 500;
}

.icons i {
  font-size: 19px;
}

hr .new1 {
  border: 1px solid;
}

.join {
  font-size: 14px;
  color: #a0a0a0;
  font-weight: bold;
}

.date {
  background-color: #ccc;
}

.gradient-custom {
  /* fallback for old browsers */
  background: #f6d365;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(
    to right bottom,
    rgba(246, 211, 101, 1),
    #eece52
  );

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right bottom, rgba(246, 211, 101, 1), #eece52);
}

.imgStyle {
  width: 100px;
}
.sectionStyle {
  background: "#100e23";
}
.div1Style {
  border-bottom-left-radius: "5px";
  width: 500px;
  background-color: #eece52;
}
.divMStyle {
  border-bottom-left-radius: "5px";
  /* width: 500px; */
  background-color: #eece52;
}
.tStyle h6 {
  color: #100e23 !important;
}
.div11Style {
  border-radius: "5px";
}

.bgStyle {
  background: "#eece52";
}
.tStyle {
  width: 500px;
}

#new_miss {
  display: none;
}

.notifs {
  position: relative;
}
.count_notif {
  position: absolute;
  font-size: 10px;
  border-radius: 50%;
  background-color: red;
  padding: 4px;
  padding-bottom: 1px !important;
  padding-top: 1px !important;
  left: 20px;
  color: white;
  text-align: center;
}
.notif_list {
  text-align: left !important;
  padding: 0px !important;
  border-radius: 3px !important;
  box-shadow: none !important;
  overflow-y: scroll;
  height: 170px;
  width: 300px;
}
.no_pointer .dropdown-item {
  cursor: default;
}
.notif_list li .dropdown-item {
  white-space: normal;
}
.notif_list .not_viewed span {
  font-weight: 900 !important;
}
.notif_list .not_viewed {
  background-color: rgb(232, 231, 231) !important;
}
.notif_tit {
  /* font-family: "Space Grotesk"; */
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
}
.notif_tit2 {
  /* font-family: "Space Grotesk"; */
  font-style: normal;
  font-size: 11px;
  word-wrap: break-word;
}
.notif_list::-webkit-scrollbar {
  /* display: none; */
  width: 7px;
  background-color: #f5f5f5;
  border-radius: 10px;
}
.notif_list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #eece52;
}

@media only screen and (max-width: 950px) {
  .hexagon {
    /* margin-left: 40%; */
  }
  .childs_row {
    height: 350px;
    /* display: flex; */
    /* flex-wrap: nowrap; */
    /* overflow-x: scroll !important; */
    overflow-y: auto !important;
  }
  .dist {
    margin-left: 0px !important;
  }
  .modal_margin {
    margin: 0px !important;
    margin-top: 10px !important;
  }
  .desktop {
    display: none !important;
  }
  .mobile {
    display: block !important;
  }
  .kid {
    font-size: 18px;
  }
  .kid-char {
    background-color: #eecf5200;
    margin: auto;
  }
  .pdash .nav-pills .nav-link {
    font-size: 12px;
  }
  .pdash .nav-pills {
    display: flex;
    justify-content: center !important;
  }
  .kid-char p {
    color: #eece52;
  }
  .kid-char a,
  .kid-char a svg path {
    color: white;
    /* fill: white; */
  }
  .kid-mission .btn {
    bottom: 5px !important;
    position: relative !important;
    width: 95%;
    left: 2.5%;
  }
  .tit {
    font-size: 16px !important;
  }

  .kid-mission {
    border: none !important;
    background: #1a182d;
  }
  .kid-mission .list-group-item {
    background: #100e23;
    margin: auto !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    width: 93% !important;
  }

  .kid-manage {
    box-shadow: 0px 0px 0px #f8d95c01;
    border: 1px solid #eece52;
  }
  .retit {
    font-size: 10px;
  }
  .added {
    font-size: 95px;
  }
  .comp {
    font-size: 47px;
  }
  .all-missions {
    width: 100% !important;
    padding-top: 75px;
    padding-bottom: 30px;
    right: 0px !important;
    margin: auto;
  }
  .miss-mobile .list-group-item {
    background-color: #eece52;
    text-align: left;
    border: none;
  }
  .miss-mobile .tit,
  .miss-mobile .desc {
    color: #100e23;
  }
  .parent-watch .title {
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
  .level-img {
    background-image: url(../public/assets/imgs/levelback-mob.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .level {
    font-size: 75px !important;
  }
  .heart {
    font-size: 35px;
  }
  .activity {
    font-size: 10px !important;
  }
  .divi {
    font-size: 20px !important;
  }
  .all {
    font-size: 20px !important;
  }
  .calory {
    font-size: 35px !important;
  }
  .flash1 {
    display: none !important;
  }
  .flash2 {
    display: none !important;
  }
  .flash3 {
    display: none !important;
  }
  .form-white {
    width: 90% !important;
  }
  .sign .nav-pills .nav-link {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }
  .sign .nav-pills .nav-item {
    padding: 10px !important;
    font-size: 13px !important;
  }
  .nav1 .btn {
    font-size: 13px !important;
  }
  .nav1 img {
    width: 15px !important;
  }
  .reward {
  }
  .poly {
    top: -340px !important;
  }
  .all-char h6 {
    left: 35% !important;
    top: 45%;
    font-size: 20.2791px !important;
  }
  .all-char p {
    left: 20% !important;
  }
}
@media only screen and (max-width: 530px) {
  .all-char p {
    left: 0px !important;
  }
  .desc {
    font-size: 12px;
  }
  .link {
    font-size: 12px;
  }
  .heart1 {
    font-size: 18px;
  }
  .hexagon {
    /* margin-left: 30%; */
    width: 100px;
    height: 100px;
  }
  .game .card-body img {
    width: 160px;
    height: 200px;
  }
  .game .card {
    margin: auto !important;
  }
  .heart {
    font-size: 20px;
  }
  .activity {
    font-size: 8px !important;
  }
  .calory {
    font-size: 20px !important;
  }
  .hover_icon {
    width: 26px;
  }
  .progress_card2 .css-118zhtq-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab {
    width: 36px !important;
    height: 36px !important;
  }
  .progress_card2 .members_icon {
    width: 30px;
  }
  .css-wuwyi7-MuiSpeedDial-root {
    z-index: 3 !important;
  }
  .progress_card2 {
    font-size: 12px !important;
  }
}
@media only screen and (max-width: 530px) and (min-width: 441px) {
}
@media only screen and (max-width: 641px) and (min-width: 530px) {
  .all-char p {
    left: 10% !important;
  }
  .all-char h6 {
    left: 25%;
  }
}
@media only screen and (max-width: 551px) and (min-width: 390px) {
  .all-char h6 {
    left: 15% !important;
  }
}

@media only screen and (max-width: 390px) {
  .pdash .nav-pills .nav-link {
    padding: 20px;
  }
  .nav-logo {
    width: 100px !important;
  }
  .all-char h6 {
    left: 10% !important;
  }
}
@media only screen and (max-width: 1160px) {
  .all-char p {
    left: 30px;
    bottom: 70px;
  }
}
@media only screen and (max-width: 500px) and (min-width: 470px) {
  .camera {
    /* left: 22%; */
  }
}
@media only screen and (max-width: 470px) and (min-width: 450px) {
  .camera {
    /* left: 20%; */
  }
}
@media only screen and (max-width: 450px) and (min-width: 420px) {
  .camera {
    /* left: 19%; */
  }
}
@media only screen and (max-width: 420px) and (min-width: 385px) {
  .camera {
    /* left: 15%; */
  }
}
@media only screen and (max-width: 384px) {
  .camera {
    /* left: 10%; */
  }
}

@media only screen and (max-width: 1260px) {
  .reward .card .card-body {
    font-size: 25px !important;
  }
  .all-char p {
    left: -10px;
  }

  .claim-num {
    font-size: 56px !important;
  }
  .flash1 {
    width: 206px;
    height: 206px;
    left: 497px;
  }
  .flash2 {
    width: 196px;
    height: 195px;
    left: 634px;
  }
  .flash3 {
    width: 188px;
    height: 187px;
    left: 589px;
    z-index: -1;
  }
  .all-char h6 {
    font-size: 40.2791px;
    left: 20%;
  }
  .character_name {
    font-size: 30px !important;
    width: 70% !important;
  }
}
@media only screen and (max-width: 1300px) and (min-width: 1250px) {
  .poly {
    width: 360px !important;
    top: -330px !important;
  }
}
@media only screen and (max-width: 1420px) and (min-width: 1260px) {
  .all-char p {
    left: -8px;
  }
}
@media only screen and (max-width: 950px) and (min-width: 865px) {
  .poly,
  .all-char p {
    display: none;
  }
  .level-char {
    position: relative;
  }
  .level-char img {
  }
}
@media only screen and (max-width: 1060px) and (min-width: 950px) {
  .all-char p {
    left: 0px;
  }
}
@media only screen and (max-width: 1160px) and (min-width: 930px) {
  .level-char img {
    width: 300px;
    height: 350px;
    margin-top: -30px;
  }
  .poly {
    width: 260px !important;
    top: -330px !important;
  }
  .watch-img img {
    width: 210px !important;
  }
}
.idd11 {
  font-size: 13px;
}
.idd12 {
  font-size: 10px;
}
.profileimg {
  height: 300px;
  width: 60%;
}
input:focus::placeholder {
  color: transparent;
}
.profilrRes {
  float: right;
  padding: 10px;
  background-color: #f6d365;
}

.profilrRes img {
  cursor: pointer;
}
.profilrRes img {
  cursor: pointer;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  border-radius: 15px;
  box-shadow: 5px 5px 5px lightblue;
  /* font-family: "Manrope"; */
  font-style: normal;

  font-size: 12px;
}

@media only screen and (max-width: 1255px) and (min-width: 1160px) {
  .poly {
    width: 350px !important;
    top: -340px !important;
  }
}
.modelstyle {
  background-color: #eece52;
  /* font-family: "Space Grotesk"; */
  font-style: normal;
  font-weight: 700;
  line-height: 25px !important;
  color: #000;
}
.modelround {
  border: 2px solid #eece52;
  border-radius: 2px;
  padding: 10px;
  margin-bottom: 10px;
}
.modal-header,
.modal-body,
.modal-footer {
  background-color: #eece52;
  /* font-family: "Space Grotesk"; */
  font-style: normal;
  font-weight: 700;
  line-height: 25px !important;
  color: #000;
  border: none;
}
.modal-open {
  padding-right: 0px !important;
}
.alig {
  text-align: center;
}
.button_right {
  padding: 10px;
}
.back {
  caret-color: #100e23 !important;
  background-color: #100e23 !important;
}
.back_a {
  margin-right: 20px;
  margin-bottom: 20px;
}

.back_a_m {
  margin-right: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.back_m {
  caret-color: #100e23 !important;
  background-color: #100e23 !important;
  justify-content: center;
  align-items: center;
  align-self: center;
  display: flex;
}
.tit_m {
  margin-left: 40px !important;
}
.desc_m {
  margin-left: 40px;
}
.box {
  inline-size: 150px;
  overflow-wrap: break-word;
  hyphens: manual;
}
.box2 {
  overflow-wrap: break-word;
}
.btn-yallow {
  background: #eece52;
  border-radius: 2px;
  color: #100e23;
  padding: 9px 16px 11px;
  font-weight: 500;
  font-size: 14px;
}
.SignUp {
  background: #100e23;
  border: 1px solid #eece52;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
}

.SignUpBtns {
  background: #100e23;
  border: 1px solid #eece52;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
  margin-left: 1%;
  margin-right: 1%;
  cursor: pointer;
}
.SignUpBtns a {
  text-decoration: none !important;
}

.SignUpBtns:hover {
  transform: scale(0.8);
  /* //transform: translateX(50%) rotate(30deg) scale(1); */
}
@keyframes anim {
  0% {
    translate: -100% 0;
  }
  100% {
    translate: 0% 0;
  }

  0%,
  100% {
    scale: 1;
  }
  5%,
  95% {
    scale: 1;
  }

  0% {
    rotate: 0deg;
  }
  10%,
  90% {
    rotate: 30deg;
  }
  100% {
    rotate: 0deg;
  }
}

.SignUpBtns {
  animation: anim 1s;
  animation-fill-mode: backwards;
}

.tableContainer {
  display: block;
}
.sticky-column {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.container_new {
  max-height: 300px;
  /* overflow-y: scroll; */
  overflow-y: scroll;
  width: 100%;
}
.container_new::-webkit-scrollbar {
  /* display: none; */
  width: 10px;

  border-radius: 10px;
  /* display: none; */
  /* width: 7px; */
  background-color: #100e23;
}
.container_new::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #eece52;
}

/* wizard with progress bar */

#heading {
  text-transform: uppercase;
  color: #673ab7;
  font-weight: normal;
}
#msform {
  text-align: center;
  position: relative;
  margin-top: 20px;
}
#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;
  position: relative;
}
.form-card {
  text-align: left;
}
#msform fieldset:not(:first-of-type) {
  display: none;
}
#msform input,
#msform textarea {
  padding: 8px 15px 8px 15px;
  border: 1px solid #ccc;
  border-radius: 0px;
  margin-bottom: 25px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  /* font-family: montserrat; */
  color: #2c3e50;
  background-color: #eceff1;
  font-size: 16px;
  letter-spacing: 1px;
}
#msform input:focus,
#msform textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #673ab7;
  outline-width: 0;
}
.no_outline:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline-width: 0 !important;
  border: 1px solid #0e081800 !important;
}
#msform .action-button {
  width: 100px;
  background: #673ab7;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 0px 10px 5px;
  float: right;
}
#msform .action-button:hover,
#msform .action-button:focus {
  background-color: #311b92;
}
#msform .action-button-previous {
  width: 100px;
  background: #616161;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px 10px 0px;
  float: right;
}
#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
  background-color: #000000;
}
.card {
  z-index: 0;
  border: none;
  position: relative;
}
.fs-title {
  font-size: 25px;
  color: #673ab7;
  margin-bottom: 15px;
  font-weight: normal;
  text-align: left;
}
.purple-text {
  color: #673ab7;
  font-weight: normal;
}
.steps {
  font-size: 25px;
  color: gray;
  margin-bottom: 10px;
  font-weight: normal;
  text-align: right;
}
.fieldlabels {
  color: gray;
  text-align: left;
}
#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey;
}
#progressbar .active {
  color: #673ab7;
}
#progressbar li {
  list-style-type: none;
  font-size: 15px;
  width: 25%;
  float: left;
  position: relative;
  font-weight: 400;
}
#progressbar #account:before {
  /* font-family: FontAwesome; */
  content: "\f13e";
}
#progressbar #personal:before {
  /* font-family: FontAwesome; */
  content: "\f007";
}
#progressbar #payment:before {
  /* font-family: FontAwesome; */
  content: "\f030";
}
#progressbar #confirm:before {
  /* font-family: FontAwesome; */
  content: "\f00c";
}
#progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px;
}
#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1;
}
#progressbar li.active:before,
#progressbar li.active:after {
  background: #673ab7;
}
.progress {
  height: 20px;
}
.progress-bar {
  background-color: #673ab7;
}
.fit-image {
  width: 100%;
  object-fit: cover;
}
.margin_p {
  margin: 5px !important;
}
.kidreward {
  border-radius: 8px;
}
.reward-img {
  /*background-image: url(./imgs/signback.png),  linear-gradient(to right,#100E23  , #100e23fa  );*/
  background-image: url(https://web1.veryality.com/assets/imgs/Missions.png),
    linear-gradient(to right, #100e23, #100e23fa);
  /*background-repeat: no-repeat;*/
  background-size: 100% 100%;
  /* background-position: center; */
  position: relative;
  border-radius: 8px;
  /* position: relative; */
  z-index: 1;
  color: #100e23;
}

.black-btn:hover {
  color: #eece52;
  background-color: #100e23;
  /* background: "#EECE52" */
}
.label_left_icon {
  /* font-family: "Space Grotesk" !important; */
  font-size: 14px;
  float: left;
  width: 100%;
}
.select__input {
  color: transparent;
}
.select_new {
  /* background-color: #262536 !important;
  color: white !important; */
  border: none;
  font-size: 12px;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: none;
  caret-color: #eee056 !important;
  border-radius: 0.25rem;
}
.testt {
  text-align: left; /* For Edge */
  text-align-last: left;
}
.reset_pass {
  float: right;
}

.inprogrsscolor {
  color: green;
}
.failedcolor {
  color: red;
}
.pagination-bar {
}

.profile-badge {
  border: 1px solid #c1c1c1;
  padding: 5px;
  position: relative;
}
.profile-pic {
  position: absolute;
  height: 120px;
  width: 120px;
  left: 50%;
  transform: translateX(-50%);
  top: 0px;
  z-index: 1001;
  padding: 10px;
}
.profile-pic img {
  border-radius: 50%;
  box-shadow: 0px 0px 5px 0px #c1c1c1;
  cursor: pointer;
  width: 100px;
  height: 100px;
}

.MissionResportStyle {
  background-color: #eece52 !important;
}
.ant-table-cell {
  background-color: #eece52 !important;
}

.ant-empty-description {
  color: black !important;
}
.newReward {
  background-color: "#262536";
}
select:focus,
input:focus {
  outline: none !important;
  border: 1px solid blue !important;
  box-shadow: 0 0 10px #719ece !important;
}
.row_new {
  /* height: 500px; */
  overflow-x: scroll;
  flex-wrap: nowrap;
}
.row_new_s {
  /* height: 500px; */
  /* overflow-x: scroll; */
  flex-wrap: nowrap;
}
.row_new_new {
  display: flex;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.row_new::-webkit-scrollbar {
  /* display: none; */
  width: 10px;
  background-color: #f5f5f5;
  border-radius: 10px;
}
.row_new::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #1c183d;
}
.ant-dropdown-menu-title-content {
  text-align: left !important;
}

.ant-dropdown-menu-title-content {
  text-align: left !important;
}
.rtl {
  direction: rtl;
}

/* LTR styles */
.ltr {
  direction: ltr;
}
.ar_menu {
  direction: rtl;
  text-align: start;
}
.nnn_ar {
  text-align: center !important;
  direction: rtl;
}
.nnn_en {
  text-align: center !important;
  direction: ltr;
}

.menu_ltr {
  text-align: left !important;
}
.menu_rtl {
  text-align: right !important;
}
.aligh_ar {
  text-align: right !important;
  padding-inline-start: 50px;
}
.aligh_en {
  text-align: left !important;
  padding-left: 50px;
}
.yallow_en {
  float: right;
  margin: 10px;
}
.yallow_ar {
  float: left;
  margin: 10px;
}
.new_ar {
  text-align: right;
  padding-right: 10px;
  direction: rtl;
}
.new_en {
  /* text-align: right; */
  /* padding-right: 10px; */
  direction: ltr;
}
.center_team {
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.featured_tournament_item {
  padding: 0 15px;
  width: -webkit-calc(100% / 3);
  width: -moz-calc(100% / 3);
  width: calc(100% / 3);
  float: left;
  margin-top: 15px;
}
.latest_tournament_list_item {
}
.featured_tournament_item_wrap {
  background: #1f2f3c;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.tournament_item_top {
  display: flex;
  /* position: relative; */
  justify-content: space-between !important;
}
.tournament_item_top_icons {
  position: absolute;
  top: 21px;
  left: 16px;
  z-index: 2;
  order: 1;
}
.tournament_item_top_status {
  position: absolute;
  top: 21px;
  z-index: 2;
  order: 2;
  right: 16px;
}
.tournament_item_top_img {
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 61.352%;
  -webkit-border-top-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topright: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  background: #17181e;
}
.tournament_item_top_bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  -webkit-border-top-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topright: 10px;
  border-top-right-radius: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-size: contain !important;
  background-repeat: no-repeat;
  background-position: center;
  transform: scale(1);
  background-image: linear-gradient(
      to left,
      rgba(61, 55, 18, 0.856),
      rgba(61, 55, 18, 0.856)
    ),
    url(./imgs/teamBack.jpeg) !important;
}
.tournament_item_top_bg:hover {
  color: #eece52 !important;
  background-color: #100e23 !important;
}
.tournament_item_top_img:hover {
  color: #eece52 !important;
  background-color: #eece52 !important;
}
.featured_tournaments_block {
  text-align: left;
  margin: 0 -15px;
  padding-bottom: 30px;
}
.tournament_item_top_price {
  line-height: 28px;
  display: inline-block;
  position: relative;
  vertical-align: top;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 800;
  padding: 0 8px 0 20px;
}
.tournament_item_top_orange .tournament_item_top_price {
  background: #f18322;
}
.tournament_item_price_link {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
}
#country_selector,
#wbbmodal .div-modal-text,
#wbbmodal .wbbm-inp-row input,
#wbbmodal button,
.back_link:before,
.bordered_btn_load .bordered_btn_txt:after,
.bordered_btn_txt,
.box-modal_close,
.btn-tooltip,
.chbx_txt,
.clone_add_btn,
.fonticon,
.foot_subscribe_input,
.friends_pull,
.friends_pull:before,
.hidden_menu_title_check,
.jq-checkbox,
.jq-checkbox__div,
.jq-checkbox__div:before,
.jq-file,
.jq-file__browse,
.jq-file__name,
.jq-selectbox,
.jq-selectbox li,
.jq-selectbox__dropdown,
.jq-selectbox__select,
.jq-selectbox__select-text,
.jq-selectbox__trigger,
.jq-selectbox__trigger-arrow,
.latest_news_category_icon_hover,
.latest_news_category_title,
.latest_news_img:before,
.latest_news_item:after,
.modal_soc_link,
.more_link:before,
.news_comments,
.notification_pull,
.notification_pull:after,
.notifications_item_accept,
.notifications_item_accept span,
.notifications_item_accept:before,
.notifications_item_decline,
.notifications_item_decline span,
.open_menu,
.pagination .next:before,
.pagination .prev:before,
.photo_file,
.players_line,
.profile_block_stat_img_bordered,
.profile_head_pull,
.ranking_line,
.ranking_more_btn span,
.scrollup,
.scrollup:before,
.search_btn,
.search_hidden,
.search_pull,
.social_ic,
.sponsor_icon,
.stream_img:before,
.tab,
.tabs_title a,
.tabs_title a:after,
.tag,
.teams_item_bg,
.teams_pull,
.tournament_item_top_img:after,
.tournament_item_top_img:before,
.type-zone,
.typical_input,
.typical_input_bordered,
.upload_btn_txt,
.wbbclose,
a {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transition-delay: 50ms;
  -moz-transition-delay: 50ms;
  -o-transition-delay: 50ms;
  transition-delay: 50ms;
}
a {
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a,
button {
  outline: 0;
  cursor: pointer;
}
.tournament_status_green_bordered {
  text-transform: uppercase;
  color: #0e1521;
  border: 1px solid #4caf50;
  padding: 5px 45px;
  border-radius: 10px;
  margin-top: -15px;
  box-sizing: border-box;
  background: #43e97d;
}
.tournament_item_top_status span {
  padding: 7px 10px 6px 23px;
  font-size: 12px;
  margin: auto;
  display: block;
  border-radius: 4px;
  font-weight: 700;
  overflow: hidden;
  position: relative;
  letter-spacing: 1px;
}
i {
  font-style: italic;
}

.tournament_item_top_status span i {
  margin-right: 2px;
  color: #000;
  opacity: 0.3;
  font-size: 20px;
  position: absolute;
  left: 0;
  top: 6px;
}
#country_selector,
#wbbmodal .div-modal-text,
#wbbmodal .wbbm-inp-row input,
#wbbmodal button,
.back_link:before,
.bordered_btn_load .bordered_btn_txt:after,
.bordered_btn_txt,
.box-modal_close,
.btn-tooltip,
.chbx_txt,
.clone_add_btn,
.fonticon,
.foot_subscribe_input,
.friends_pull,
.friends_pull:before,
.hidden_menu_title_check,
.jq-checkbox,
.jq-checkbox__div,
.jq-checkbox__div:before,
.jq-file,
.jq-file__browse,
.jq-file__name,
.jq-selectbox,
.jq-selectbox li,
.jq-selectbox__dropdown,
.jq-selectbox__select,
.jq-selectbox__select-text,
.jq-selectbox__trigger,
.jq-selectbox__trigger-arrow,
.latest_news_category_icon_hover,
.latest_news_category_title,
.latest_news_img:before,
.latest_news_item:after,
.modal_soc_link,
.more_link:before,
.news_comments,
.notification_pull,
.notification_pull:after,
.notifications_item_accept,
.notifications_item_accept span,
.notifications_item_accept:before,
.notifications_item_decline,
.notifications_item_decline span,
.open_menu,
.pagination .next:before,
.pagination .prev:before,
.photo_file,
.players_line,
.profile_block_stat_img_bordered,
.profile_head_pull,
.ranking_line,
.ranking_more_btn span,
.scrollup,
.scrollup:before,
.search_btn,
.search_hidden,
.search_pull,
.social_ic,
.sponsor_icon,
.stream_img:before,
.tab,
.tabs_title a,
.tabs_title a:after,
.tag,
.teams_item_bg,
.teams_pull,
.tournament_item_top_img:after,
.tournament_item_top_img:before,
.type-zone,
.typical_input,
.typical_input_bordered,
.upload_btn_txt,
.wbbclose,
a {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transition-delay: 50ms;
  -moz-transition-delay: 50ms;
  -o-transition-delay: 50ms;
  transition-delay: 50ms;
}
.featured_tournaments_block {
  text-align: left;
  margin: 0 -15px;
  padding-bottom: 30px;
}
.featured_tournaments_block {
  text-align: left;
  margin: 0 -15px;
  padding-bottom: 30px;
}

.featured_tournaments_block .featured_tournament_item:nth-child(1),
.featured_tournaments_block .featured_tournament_item:nth-child(2) {
  width: -webkit-calc(100% / 2);
  width: -moz-calc(100% / 2);
  width: 100%;
}
.featured_tournament_item {
  padding: 0 15px;
  width: -webkit-calc(100% / 2);
  width: -moz-calc(100% / 2);
  width: 100%;
  float: left;
  margin-top: 5px;
}
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
::-webkit-scrollbar {
  width: 0px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.tournament_item_btm_info i {
  color: #6b7996;
}
.tournament_item_btm_info span {
  font-size: 13px;
  font-weight: 700;
}
.tournament_item_end,
.tournament_item_start {
  /* position: relative; */
  font-size: 16px;
  color: #6b7996;
  line-height: 18px;
  /* display: inline-block; */
  /* vertical-align: top; */
  /* text-transform: uppercase; */
  padding: 3px;
  /* margin-right: 10px; */
}
.tournament_item_btm_info:before,
.tournament_item_start:before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 15px;
  height: 15px;
  margin-top: -10px;
  text-align: left !important;
}
.tournament_item_btm_info {
  /* text-align: left  !important; */
  margin-top: 10px;
  margin-bottom: 10px;
}
.fa-calendar-alt:before {
  content: "\f073";
}
.tournament_status_green_bordered {
  text-transform: uppercase;
  color: #0e1521;
  border: 1px solid #4caf50;
  padding: 5px 45px;
  border-radius: 10px;
  margin-top: -15px;
  box-sizing: border-box;
  background: #43e97d;
}
.fa-check-double:before {
  content: "\f560";
}

/* i {
  font-style: italic;
} */
.tournament_item_top_status span {
  padding: 7px 10px 6px 23px;
  font-size: 12px;
  margin: auto;
  display: block;
  border-radius: 4px;
  font-weight: 700;
  overflow: hidden;
  position: relative;
  letter-spacing: 1px;
}
.tournament_status_green_bordered {
  text-transform: uppercase;
  color: #0e1521;
  border: 1px solid #4caf50;
  padding: 5px 45px;
  border-radius: 10px;
  margin-top: -15px;
  box-sizing: border-box;
  background: #43e97d;
}
.featured_tournaments_block {
  text-align: left;
  margin: 0 -15px;
  padding-bottom: 30px;
}

.fa-check-double:before {
  content: "\f560";
}
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.tournament_item_top_status span i {
  margin-right: 2px;
  color: #000;
  opacity: 0.3;
  font-size: 20px;
  position: absolute;
  left: 0;
  top: 6px;
}

.tournament_item_top_status span {
  padding: 7px 10px 6px 23px;
  font-size: 12px;
  margin: auto;
  display: block;
  border-radius: 4px;
  font-weight: 700;
  overflow: hidden;
  position: relative;
  letter-spacing: 1px;
}
.tournament_status_green_bordered {
  text-transform: uppercase;
  color: #0e1521;
  border: 1px solid #4caf50;
  padding: 5px 45px;
  border-radius: 10px;
  margin-top: -15px;
  box-sizing: border-box;
  background: #43e97d;
}
.featured_tournaments_block {
  text-align: left;
  margin: 0 -15px;
  padding-bottom: 30px;
}
.upload_btn_txt,
.wbbclose,
a {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transition-delay: 50ms;
  -moz-transition-delay: 50ms;
  -o-transition-delay: 50ms;
  transition-delay: 50ms;
}
.tournament_item_title {
  display: block;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  font-size: 20px;
  font-weight: 800;
  line-height: 40px;
  margin-bottom: 20px;
}
.tournament_item_progress {
  display: block;
  background: #35434d;
  height: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  position: relative;
  margin-top: -20px;
  margin-bottom: 20px;
}

.featured_tournaments_block {
  text-align: left;
  margin: 0 -15px;
  padding-bottom: 30px;
}

.tournament_item_progress_all {
  font-size: 16px;
  color: #6b7996;
  line-height: 25px;
  display: inline-block;
  vertical-align: top;
  /* text-transform: uppercase; */
  float: left;
  /* font-family: Rogan!important; */
}
.tournament_item_progress_left {
  font-size: 16px;
  color: #6b7996;
  line-height: 25px;
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
  float: right;
}
.tournament_item_btm {
  height: 300px;
  padding: 0 20px;
}
.featured_tournament_item_wrap {
  background: #1f2f3c;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  /* border-radius: 10px; */
  border-radius: 0px 0px 10px 10px;
}
.tournament_item_top {
  position: relative;
}
/*
*/
.review_progress .css-eglki6-MuiLinearProgress-root {
  background-color: #f2f2f2 !important;
  height: 10px;
  border-radius: 2px;
}
.review_progress .css-5xe99f-MuiLinearProgress-bar1 {
  background-color: #f2c94c !important;
}
.progress_card {
  background-image: linear-gradient(to left, #14102adc, #14102adc),
    url(./imgs/teamBack.jpeg) !important;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  color: white;
  border-radius: 25px;
  /* opacity: 0.4; */
}
.progress_card:hover {
  /* background-image: linear-gradient(
      to left,
      rgba(61, 55, 18, 0.856),
      rgba(61, 55, 18, 0.856)
    ),
    url(./imgs/teamBack.jpeg) !important; */
}
.icon_1 {
  margin-right: 2px;
  color: #000;
  opacity: 0.3;
  font-size: 20px;
  position: absolute;
  left: 0;
  top: 4px;
}
.hexagonnew {
  width: 50px;
  height: 50px;
  /* -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%); */
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  /* margin-left: 23%; */
  margin: auto !important;
}
.p_l {
  padding-left: 2rem !important;
}
.new_p {
  padding: 20px !important;
}
.tournament_item_progress_info {
  word-wrap: break-word !important;
}
.f_right {
  float: right;
  direction: rtl;
}
.mob_ar {
  text-align: start !important;
}
.mob_en {
  text-align: end !important;
}
.arabicText {
  /* font-family: 'Cairo', sans-serif; Apply Cairo font to the Arabic text */
}
.css-wuwyi7-MuiSpeedDial-root {
  z-index: 3 !important;
}
.rtl_rightAlign{
  direction: rtl;
  text-align: right !important;
}
.custom_mission_rtl_table .ant-table{
    direction: rtl;
}
#games .col-md-4,#latest .col-md-4{
  width: 23.8% !important;
}
.MuiChip-deleteIcon {
  color: red !important; /* Set the color of the delete icon here */
}
.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: white !important; /* Set the color of the delete icon here */
}
.glow-on-hover {


  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 0px;
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #2c5ee0, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  position: absolute;
  top: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 0px;
}

.glow-on-hover:active {
  color: #000
}

.glow-on-hover:after {
  background: transparent;
}

.glow-on-hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #2a49b6;
  left: 0;
  top: 0;
  border-radius: 0px;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}

